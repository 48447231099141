import moment from "moment";

export class Utils {
  static makeEmptyArray(length) {
    const arr = [];
    for (let i = 0; i < length; i++) {
      arr.push({});
    }

    return arr;
  }

  static queryString(params) {
    var queryString = Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&');
    return queryString;
  }

  static removeTrailingSlash(path) {
    return path.replace(/\/$/, "");
  }

  static unshiftArrayFixed(array, item, length) {
    array.unshift(item);

    return array.slice(0, Math.min(array.length, length - 1));
  }

  static makeId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  static toIsoStringWithTimezone(date) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function(num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };
    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);
  }
  static getObjectId = (obj) => obj?.id || obj?._id;

}

export const dateWithFormat = (strDate, format = 'MM/DD/YY') => {
  return strDate ? moment(strDate).format(format) : ''
};

export const isUrlImage = (url) => url?.match(/.(jpg|jpeg|png|gif)$/i);


export const fileDownloadV1 = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  
  // Revoke the object URL to free up memory
  window.URL.revokeObjectURL(url);
};
