import React, {Component} from "react";
import {connect} from "react-redux";
import {Route, Redirect} from "react-router";
import ROUTES from "../routes/constants";
import PropTypes from "prop-types";

class AuthRoute extends Component {
  static propTypes = {
    component: PropTypes.object
  };

  render() {
    const Component = this.props.component;
    // console.log(this.props.UserReducer.user);
    return (
      <Route
        render={props => this.props.UserReducer.user
          ? <Component {...props} />
          : <Redirect to={{pathname: ROUTES.LOGIN, state: {from: props.location}}} />}
      />
    );
  }
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, null)(AuthRoute);
