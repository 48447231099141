import React, { Component } from 'react';
import './App.scss';
import {BrowserRouter as Router} from "react-router-dom";
import {Switch, Route} from "react-router";
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserAlt,
  faSearch,
  faChevronDown,
  faChevronUp,
  faCaretDown,
  faCaretUp,
  faEye,
  faEnvelope,
  faEyeSlash,
  faPlusCircle,
  faPlus,
  faSort,
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faImage,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import {setUserInfo} from "./actions/user";
import {connect} from 'react-redux';
import ROUTES from "./routes/constants";
import {AuthService} from "./services";
import AuthRoute from "./components/AuthRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Pages
const Login = React.lazy(() => import('./pages/login/Login'));
const OtpVerification = React.lazy(() => import('./pages/login/OtpVerification'));
const Register = React.lazy(() => import('./pages/register/Register'));
const Layout = React.lazy(() => import('./pages/main/Layout'));

// init icons
library.add(
  faUserAlt,
  faSearch,
  faChevronDown,
  faCaretDown,
  faChevronUp,
  faCaretUp,
  faEye,
  faEyeSlash,
  faEnvelope,
  faPlusCircle,
  faPlus,
  far,
  faSort,
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faImage,
  faCheck,
);

class App extends Component {
  currentUser = null;

  constructor(props) {
    super(props);

    this.currentUser = props.UserReducer.user;

    if (!this.currentUser) {
      // get user from cookie
      this.currentUser = AuthService.getCurrentUser();
    }
  }

  render() {
    return (
      <Router basename={'/user'}>
        <ToastContainer />
        <React.Suspense fallback={this.loading}>
          <Switch>
            <Route exact path={ROUTES.LOGIN} name="Login Page" render={props => <Login {...props}/>} />
            <Route exact path={ROUTES.OTP_VERIFICATION} name="Otp Verification" render={props => <OtpVerification {...props}/>} />
            <Route exact path={ROUTES.REGISTER} name="Register Page" render={props => <Register {...props}/>} />
            <AuthRoute path="/" name="Home" component={Layout} />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }

  loading = (
    <div className="pt-3 text-center">
    </div>
  );
}

const mapStateToProps = (state) => state;

const mapDispatchToProps = {
  setUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
